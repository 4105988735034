import Head from 'next/head'

import { Hero } from '@/components/UI/Hero'
import { PrimaryFeatures } from '@/components/UI/PrimaryFeatures'
import { SecondaryFeatures } from '@/components/UI/SecondaryFeatures'
import { CallToAction } from '@/components/UI/CallToAction'
import { FAQ } from '@/components/UI/FAQ'

export default function Home() {
  return (
    <>
      <Head>
        <title>
          OpenSpeech - AI Speech recognition & transcript generation for Teams
        </title>
      </Head>
      <main>
        <>
          <Hero />
          {/* <SecondaryFeatures /> */}
          <PrimaryFeatures />
          <CallToAction />
          <FAQ></FAQ>
        </>
      </main>
    </>
  )
}
