import {
  UsersIcon,
  HashtagIcon,
  PlayIcon,
  UserGroupIcon,
  ShareIcon,
  DocumentTextIcon,
  PencilIcon,
  EnvelopeIcon,
} from '@heroicons/react/20/solid';
import Image from 'next/image'
import transcript_analytics from '/public/images/transcript_analytics.png'

const features = [
  {
    name: 'Speaker Detection',
    description: 'Automatically identify and distinguish between different speakers in your audio.',
    icon: UsersIcon,
  },
  {
    name: 'Keywords',
    description: 'Identify and extract relevant keywords to optimize content discoverability.',
    icon: HashtagIcon,
  },
  {
    name: 'Audio player',
    description: 'Embed a customizable audio player to facilitate easy playback of your content.',
    icon: PlayIcon,
  },
  {
    name: 'LinkedIn posts',
    description: 'Craft professional LinkedIn posts to share valuable insights from your audio content.',
    icon: UserGroupIcon,
  },
  {
    name: 'Twitter threads',
    description: 'Generate insightful Twitter threads to expand your reach and spark conversations.',
    icon: ShareIcon,
  },
  {
    name: 'Blog posts',
    description: 'Create captivating blog posts to showcase your audio content and reach a wider audience.',
    icon: PencilIcon,
  },
  {
    name: 'Newsletter posts',
    description: 'Curate engaging newsletter content, summarizing key takeaways from your audio.',
    icon: EnvelopeIcon,
  },
  {
    name: 'Audio transcription',
    description: 'Obtain accurate transcripts to provide an accessible, readable version of your audio.',
    icon: DocumentTextIcon,
  },
];

// export function PrimaryFeatures() {
//   return (
//     <section className="w-full bg-white" id="features">
//       <div className="ml-auto mr-auto max-w-7xl px-10 pt-16 md:px-24 lg:px-12">
//         <div className="grid gap-5 py-12 lg:grid-cols-2">
//           <div className="flex flex-col justify-center md:pr-8 lg:max-w-lg xl:pr-0">
//             <div className="mb-5 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-r from-indigo-600 to-purple-500">
//               <svg
//                 className="h-10 w-10 text-white"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
//               </svg>
//             </div>
//             <div className="mb-6 max-w-xl">
//               <div className="mb-6">
//                 <p className="inline font-sans text-5xl font-bold tracking-tight text-gray-800 sm:text-6xl sm:leading-none">
//                   Speak Up,
//                 </p>
//                 <p className="block font-sans text-5xl font-bold tracking-tight text-purple-700 sm:text-6xl sm:leading-none lg:text-indigo-600">
//                   we&apos;ve got you covered
//                 </p>
//               </div>
//               <p className="text-base text-gray-800 md:text-lg lg:text-base">
//               Empower yourself with OpenSpeech, the ultimate tool for improving your public speaking skills, enhancing client communication, or elevating the quality of your podcast. With OpenSpeech, you gain access to valuable insights and tools that will help you achieve your goals and succeed.
//               </p>
//             </div>
//             <Button href="/register" className="w-max rounded-4xl px-8 py-4 shadow-md transition-all duration-100 hover:shadow-xl" >
//               Request a Demo
//             </Button>
//           </div>
//           <div className="flex w-full justify-center lg:items-center">
//             <div className="flex flex-col items-end pr-3">
//               <Image
//                 src="https://cdn.devdojo.com/images/july2021/content-19-1.jpg"
//                 className="mb-6 h-full w-full rounded object-cover shadow-lg lg:h-48 lg:w-48 xl:h-56 xl:w-56"
//                 width={400}
//                 height={400}
//                 alt="OpenSpeech"
//               />
//               <Image
//                 src="https://cdn.devdojo.com/images/july2021/content-19-2.jpg"
//                 className="h-full w-full rounded object-cover shadow-lg lg:h-32 lg:w-32 xl:h-40 xl:w-40"
//                 wixdth={400}
//                 height={400}
//                 alt="OpenSpeech"
//               />
//             </div>
//             <div className="pl-3">
//               <Image
//                 src="https://cdn.devdojo.com/images/july2021/content-19-3.jpg"
//                 className="h-full w-full rounded object-cover shadow-lg lg:h-64 lg:w-64 xl:h-80 xl:w-80"
//                 width={400}
//                 height={400}
//                 alt="OpenSpeech"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

export function PrimaryFeatures() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Experience the Revolution in Audio Transcription!</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Unlock effortless audio transcription with OpenSpeech. Transform audio into text seamlessly, enhance searchability, and boost your productivity. Simplify and streamline your content process with OpenSpeech.          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">       
        <div className="mx-auto max-w-7xl px-6 lg:px-8">          
          <Image
            src={transcript_analytics}
            alt="Cloud illustration"
            width={2432}
            height={1442}
            className="rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="font-semibold text-gray-900">
                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
