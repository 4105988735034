import React, { useState, useEffect } from 'react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Link  from 'next/link';
import hero from '/public/images/hero.png'
import Image from 'next/image';

export function Hero() {
  const [index, setIndex] = useState(0)
  const texts = [
    'Analysis',
    "Shownotes",
    "Reports",
    "Summaries",
    "Transcripts",
    "Blog Posts",
    "Social Posts",
  ]
  
useEffect(() => {
  const intervalId = setInterval(() => {
    setIndex((index) => (index + 1) % texts.length)
  }, 1000)

  return () => clearInterval(intervalId)
}, [texts.length])
  
  
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:px-8 lg:py-20">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <div className="mt-10 sm:mt-6 lg:mt-8">
                  <Link href="/pricing" className="inline-flex space-x-6">
                    <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                      Beta
                    </span>
                    <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                      <span>Just shipped v1.0.0</span>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Link>
                </div>
                <h1 className="mt-10 text-4xl font-bold tracking-tight leading-10 text-gray-900 sm:text-6xl">
                Supercharge your audio files into
                  <span
                    className=" text-indigo-600"
                    style={{ animation: 'slide-in 1s ease-out forwards' }}
                  >
                    {' '}
                    {texts[index]}
                  </span>
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                Enhance your audio content seamlessly with OpenSpeech&apos;s advanced speech recognition AI technology.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    href="/register"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started for free
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-indigo-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                        <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                              <Image
                                src={hero}
                                className="tails-preview mt-20 mb-20 ml-0 h-auto w-full rounded-xl lg:h-auto lg:w-full"
                                data-rounded="rounded-xl"
                                data-rounded-max="rounded-full"
                                width={800}
                                height={600}
                                alt="Dashboard"
                              />    
                        </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  )
}
