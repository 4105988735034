import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    id: 1,
    question: "What services does your company provide?",
    answer:
      "We provide audio-to-text transcription, content analysis, keyword extraction, and content generation services."
  },
  {
    id: 2,
    question: "How accurate is your audio-to-text transcription service?",
    answer:
      "Our service is highly accurate, typically achieving a 95% or higher accuracy rate, depending on factors like audio quality."
  },
  {
    id: 3,
    question: "Can your platform handle multiple speakers and different accents?",
    answer:
      "Yes, our platform can recognize and differentiate between multiple speakers and various accents."
  },
  {
    id: 4,
    question: "How do you ensure the confidentiality and security of my audio files?",
    answer:
      "We encrypt all files during transmission and storage, and restrict access to authorized personnel only."
  },
  {
    id: 5,
    question: "What file formats do you support for audio-to-text transcription?",
    answer:
      "We support various formats, including MP3, WAV, M4A, OGG, and more."
  },
  {
    id: 6,
    question: "What is content analysis, and how does it help my business?",
    answer:
      "Content analysis extracts insights like keywords and topics, helping you optimize your content strategy and marketing efforts."
  },
  {
    id: 7,
    question: "How does your content generation service work?",
    answer:
      "Our service uses natural language processing algorithms to create relevant content based on transcriptions and analysis insights."
  },
  {
    id: 8,
    question: "What are the pricing plans for your services?",
    answer:
      "We offer various pricing plans based on your project's scope and requirements. Contact our sales team for more information."
  }
]

export function FAQ() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
              sending us an email
            </a>{' '}
            and we’ll get back to you as soon as we can.
          </p>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
